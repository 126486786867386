import { Box, Button, Checkbox, FormControl, Grid, IconButton, InputAdornment, InputLabel, OutlinedInput, ThemeProvider, Tooltip, Typography } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { cardItem, colorTheme, labelItem, paymentModes, transactionBtn } from '../../Common/Theme';
import DynamicSearchBox from '../../Common/DynamicSearchBox';
import { ChevronLeft, DeleteOutline, Search } from '@mui/icons-material';
import { useGetProductsQuery, useGetProductTaxMutation } from '../../Shared/Store/Api/productApi';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { exchangeProductsAction } from '../../Shared/Store/Slice/ExchangeProduct';
import { toast } from 'react-toastify';
import { IUpdatedProducts } from '../../Shared/Types/types';
import { useExchangeInvoiceMutation, useExchangeValidateMutation, useLazyGetReturnInvoiceQuery } from '../../Shared/Store/Api/returnApi';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import cash from '../../Assets/images/cash.png';
import upi from '../../Assets/images/upi.png';
import multiModes from '../../Assets/images/multi-modes.png';
import creditCard from '../../Assets/images/credit-card.png';
import PaymentModal from '../Billing/PaymentModal';
import PrintButton from './PrintButton';


const ExchangeProducts = () => {

    const Item = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: '20px',
        gap: '15px',
    }));

    const navigate = useNavigate();
    const backToInvoice = () => {
        navigate("/invoices")
    }
    const dispatch = useDispatch();
    const [searchQuery, setSearchQuery] = useState('');
    const [searchInvoice, setSearchInvoice] = useState('');
    const searchProduct = useRef<HTMLDivElement>(null);
    const { data: products, refetch } = useGetProductsQuery({ search: searchQuery });
    const [getTax] = useGetProductTaxMutation();
    const { selectedProducts } = useSelector((state: any) => state.exchangeProducts);
    const [getReturnInvoice, { data: invoiceDetails }] = useLazyGetReturnInvoiceQuery();
    const productDetails = invoiceDetails?.results[0]?.invoice_prod_details || [];

    const [selectedExchangeProducts, setExchangeProducts] = useState<any[]>([]);

    const [exchangeValidate] = useExchangeValidateMutation();
    const [exchangeSubmit] = useExchangeInvoiceMutation();

    const [creditNote, setCreditNote] = useState<any | null>(null);
    const credit_note = creditNote?.credit_note;
    const bill_total = creditNote?.bill_total;
    const [invoiceNumber, setInvoiceNumber] = useState();
    const [paymentAmt, setPaymentAmt] = useState(0);
    const [transactionCode, setTransactionCode] = useState('');
    const [paymentAmtBtn, setPaymentAmtBtn] = useState(false);
    const [open, setOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState('');
    const [payments, setPayments] = useState<any[]>([]);
    const [halfPaymentValue, setHalfPaymentValue] = useState(false);

    const handleOpen = (title: string) => {
        setModalTitle(title);
        setOpen(true);
    };

    const handleClose = () => {
        setPaymentAmt(0);
        setPaymentAmtBtn(false);
        setTransactionCode('');
        setPayments([]);
        setOpen(false);
    };


    const handleSetPayment = (types: string, typesValue: boolean) => {
        if (typesValue === true) {
            setPayments((payments: any) => [
                ...payments,
                {
                    "payment_mode": types,
                    "total_amt": bill_total,
                    "short_amt": 0,
                    "total_paid_amt": paymentAmt,
                    "transaction_code": transactionCode || "",
                    "status": "Partial Payment",
                    "desc": "",
                    "payment_dt": formattedFullDate
                }
            ]);
        }
        else {
            setPayments((payments: any) =>
                payments.filter((payment: any) => payment.payment_mode !== types)
            );
        }
    }

    const paymnetHandler = (e: any, types: string) => {
        setPaymentAmt(parseFloat(e.target.value));
        setPaymentAmtBtn(true);
        const payment = payments.find((payment: any) => payment.payment_mode === types);
        if (payment) {
            setPayments((payments: any) =>
                payments.map((paymnet: any) =>
                    paymnet.payment_mode === types
                        ? { ...paymnet, total_paid_amt: parseFloat(e.target.value) }
                        : paymnet
                )
            );
        }
    }

    const transactionCodeHandler = (e: any) => {
        setTransactionCode(e.target.value);
    }

    const halfPaymentHandle = (halfPayment: boolean) => {
        setHalfPaymentValue(halfPayment);
    }

    const adjustShortAmount = () => {
        const totalPaidAmount = payments.reduce((acc, payment) => acc + payment.total_paid_amt, 0);
        const totalShortAmount = bill_total - totalPaidAmount;
        if (payments.length > 0) {
            setPayments((payments: any) =>
                payments.map((payment: any, index: number) => ({
                    ...payment,
                    short_amt: halfPaymentValue ? 0 : index === 0 ? totalShortAmount : 0
                }))
            );
        }
    }

    useEffect(() => {
        adjustShortAmount();
    }, [payments, bill_total, halfPaymentValue, adjustShortAmount]);

    const today = new Date();
    const year = today.getFullYear();
    const month = today.getMonth() + 1;
    const day = today.getDate();
    const customDate = `${year}-${month < 10 ? '0' + month : month}-${day < 10 ? '0' + day : day}`;
    const formattedDate = new Date(customDate);
    const formattedDateDay = formattedDate.getDate();
    const formattedDateMonth = formattedDate.getMonth() + 1;
    const formattedDateYear = formattedDate.getFullYear();
    const formattedFullDate = `${formattedDateDay < 10 ? '0' + formattedDateDay : formattedDateDay}-${formattedDateMonth < 10 ? '0' + formattedDateMonth : formattedDateMonth}-${formattedDateYear}`


    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (searchProduct.current && !searchProduct.current.contains(event.target)) {
                setSearchQuery('');
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [searchProduct, setSearchQuery]);

    useEffect(() => {
        if (searchQuery) {
            refetch();
        }
    }, [searchQuery, refetch]);

    const handleSearchChange = (e: any) => {
        setSearchQuery(e.target.value);
    };

    const handleInvoiceSearch = (e: any) => {
        setSearchInvoice(e.target.value);
        getReturnInvoice({ search: e.target.value });
    };

    const handleProductClick = async (product: any) => {
        setSearchQuery('');
        const productDetails = {
            price: product.avg_selling_rate,
            place_of_supply: "24",
            hsn_code: product.hsn_id,
            prod_sku: product.prod_sku,
        };
        try {
            await getTax(productDetails).unwrap().then((res: any) => {
                const prodTaxData = res;
                if (prodTaxData && productDetails.prod_sku === product.prod_sku) {
                    const updatedProductItem: IUpdatedProducts = {
                        "prod_title": product.prod_title,
                        "barCode": product.barCode,
                        "quantity_at_hand": product.quantity_at_hand,
                        "prod_sku": product.prod_sku,
                        "hsn_code": product.hsn_id,
                        "unit_price": product.avg_selling_rate,
                        "quantity": 1,
                        "disc_type": 'Flat',
                        "mrp": product.mrp,
                        "sub_total": product.avg_selling_rate,
                        "sub_total_disc": product.avg_selling_rate,
                        "tax_rate": prodTaxData.tax_rate,
                        "tax_rate_name": prodTaxData.tax_rate_name,
                        "tax_amt": prodTaxData.tax_amt,
                        "net_total": prodTaxData.total_amt_before_tax,
                        "hsn_tax": prodTaxData.hsn_tax,
                        "size": product.size,
                        "colour": product.color,
                        "discount_flag": product.discount_flag,
                        "prodBarCode": product.prodBarCode
                    };
                    dispatch(exchangeProductsAction.addProducts(updatedProductItem));
                }
            });
        } catch (error) {
            toast.error("Something went wrong!");
        }
    };


    const increment = (product: any) => {
        dispatch(exchangeProductsAction.updateProductQuantity({ product, actionType: "increment", }))

        // if (product.quantity < product.quantity_at_hand) {
        // } else {
        //     toast.error("Maximum quantity reached");
        // }
    };

    const decrement = (product: any) => {
        dispatch(exchangeProductsAction.updateProductQuantity({ product, actionType: "decrement", }))
    };

    const handleProductRemove = (barCode: any) => {
        dispatch(exchangeProductsAction.removeProduct({ barCode }));
    };

    const handleCheckboxChange = (product: any) => {
        setExchangeProducts(prevSelected => {
            if (prevSelected.some(p => p.prod_sku === product.prod_sku)) {
                return prevSelected.filter(p => p.prod_sku !== product.prod_sku);
            } else {
                return [
                    ...prevSelected,
                    {
                        ...product,
                        quantity: product.quantity || 1,
                        return_unit_price: product.return_unit_price * (product.quantity || 1)
                    }
                ];
            }
        });
    };

    const handleQuantityChange = (product: any, quantity: number) => {
        if (quantity > product.quantity) {
            toast.error(`You cannot select more than ${product.quantity} for this product.`);
            return;
        }

        setExchangeProducts(prevSelected =>
            prevSelected.map(p =>
                p.prod_sku === product.prod_sku
                    ? {
                        ...p,
                        quantity: quantity,
                        return_unit_price: product.return_unit_price * quantity
                    }
                    : p
            )
        );
    };


    const exchangeValidateHandler = async () => {
        const invoiceData = {
            "dt_sequence_no": invoiceDetails?.results[0]?.dt_sequence_no,
            "customer_name": invoiceDetails?.results[0]?.customer_name,
            "customer_contact": invoiceDetails?.results[0]?.customer_contact,
            "product_details": selectedExchangeProducts,
            "new_products": selectedProducts
        };

        try {
            await exchangeValidate(invoiceData).unwrap().then((res: any) => {
                if (res) {
                    setCreditNote(res);
                }
                toast.success('Exchange validate successfully');
            });
        } catch (error: any) {
            toast.error(error?.data?.non_field_errors[0]);
        }
    };

    const adjustedShortAmt = Math.round(bill_total) - paymentAmt;

    const exchangeSubmitHandler = async () => {
        const paymentMode = modalTitle === 'Card' ? 'Credit Card' : modalTitle;
        const isPartialPayment = modalTitle === 'Multi Modes';
        const invoiceData: any = {
            "dt_sequence_no": invoiceDetails?.results[0]?.dt_sequence_no,
            "credit_note": credit_note,
            "bill_total": bill_total,
            "product_details": selectedExchangeProducts,
            "new_products": selectedProducts
        };

        // Conditionally add customer_payments if credit_note is false
        if (!credit_note) {
            invoiceData.customer_payments = isPartialPayment
                ? payments
                : [{
                    "payment_mode": paymentMode,
                    "total_amt": bill_total,
                    "short_amt": halfPaymentValue ? 0 : adjustedShortAmt,
                    "total_paid_amt": paymentAmt,
                    "transaction_code": transactionCode || "",
                    "status": "Paid",
                    "desc": "",
                    "payment_dt": formattedFullDate
                }];
        }


        try {
            await exchangeSubmit(invoiceData).unwrap().then((res: any) => {
                if (res) {
                    setInvoiceNumber(res?.dt_sequence_no);
                }
                setOpen(false);
                toast.success('Exchange submit successfully');
            });
        } catch (error: any) {
            toast.error(error?.data?.non_field_errors[0]);
        }
    };

    return (
        <>
            <section id="main" className="flex flex-col overflow-auto flex-1 p-3 gap-3 pb-32">
                <Grid container spacing={2} justifyContent="space-between">

                    <Grid item xs={12} sx={{ marginTop: 'auto', display: 'flex', justifyContent: 'end' }} >
                        <Button
                            sx={{ fontWeight: "600", color: colorTheme.palette.indigo500, alignItems: 'center', padding: 0, display: 'flex', lineHeight: '25px' }}
                            onClick={() => {
                                backToInvoice();
                            }}
                        >
                            <ChevronLeft />
                            Back to Invoices
                        </Button>
                    </Grid>

                    <Grid item xs={6} md={6} sx={{ paddingTop: '0px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ ...cardItem, overflow: 'visible' }}>
                                    <Grid container spacing={3} alignItems='center' justifyContent='space-between'>
                                        <Grid item xs={12} md={6}>
                                            <DynamicSearchBox>
                                                <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                                    <InputLabel htmlFor="outlined-adornment-password">Search invoice</InputLabel>
                                                    <OutlinedInput
                                                        value={searchInvoice}
                                                        onChange={handleInvoiceSearch}
                                                        type='text'
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <Search />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Search invoice"
                                                    />
                                                </FormControl>
                                            </DynamicSearchBox>
                                        </Grid>
                                        <Grid item xs={12}>
                                            {productDetails.length > 0 ? (
                                                <table className="w-full customTable">
                                                    <thead>
                                                        <tr>
                                                            <th className="text-left"></th>
                                                            <th className="text-left">Barcode</th>
                                                            <th className="text-left">Product</th>
                                                            <th className="text-left">Qty</th>
                                                            <th className="text-left">Unit Price</th>
                                                            <th className="text-left">Subtotal</th>
                                                            <th className="text-left">Return Price</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {productDetails.map((product: any, index: number) => (
                                                            <tr key={index}>
                                                                <td className='checkBoxWraper'>
                                                                    <Checkbox
                                                                        checked={selectedExchangeProducts.some(p => p.prod_sku === product.prod_sku)}
                                                                        onChange={() => handleCheckboxChange(product)}
                                                                    />
                                                                </td>
                                                                <td>
                                                                    <Grid container spacing={0} alignItems='center'>
                                                                        <Grid item xs={12}>
                                                                            {String(product.prodBarCode)}
                                                                        </Grid>
                                                                        <Grid item xs='auto'>
                                                                            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                SKU:&nbsp;{String(product.prod_sku)}
                                                                            </Typography>
                                                                        </Grid>
                                                                    </Grid>
                                                                </td>
                                                                <td>
                                                                    <Grid container spacing={1} alignItems='center'>
                                                                        <Grid item>
                                                                            {String(product.product_title)}
                                                                        </Grid>
                                                                        {product.size && (
                                                                            <Grid item xs='auto'>
                                                                                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                    Size:&nbsp;{String(product.size)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                        {product.colour && (
                                                                            <Grid item xs='auto'>
                                                                                <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                    Color:&nbsp;{String(product.colour)}
                                                                                </Typography>
                                                                            </Grid>
                                                                        )}
                                                                    </Grid>
                                                                </td>
                                                                <td>
                                                                    <input
                                                                        type="number"
                                                                        placeholder="0"
                                                                        value={selectedExchangeProducts.find(p => p.prod_sku === product.prod_sku)?.quantity || product.quantity}
                                                                        onChange={(e) => handleQuantityChange(product, Number(e.target.value))}
                                                                        className="discountInp text-center"
                                                                        disabled={!selectedExchangeProducts.some(p => p.prod_sku === product.prod_sku)}
                                                                        min={1}
                                                                        max={product.quantity}
                                                                    />
                                                                </td>
                                                                <td>{product.unit_price}</td>
                                                                <td>{product.sub_total}</td>
                                                                <td>
                                                                    {selectedExchangeProducts.find(p => p.prod_sku === product.prod_sku)?.return_unit_price || product.return_unit_price}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            ) : (
                                                <Typography sx={{ textAlign: "center" }}>No data found</Typography>
                                            )}
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={6} sx={{ paddingTop: '0px' }}>
                        <Grid container spacing={2}>
                            <Grid item xs={12}>
                                <Box sx={{ ...cardItem, overflow: 'visible' }}>
                                    <Grid container spacing={3} alignItems='center'>
                                        <Grid item xs={12} md={6}>
                                            <DynamicSearchBox>
                                                <FormControl variant="outlined" sx={{ borderColor: '#bbb', width: '100%' }} size='small'>
                                                    <InputLabel htmlFor="outlined-adornment-password">Search product/barcode</InputLabel>
                                                    <OutlinedInput
                                                        value={searchQuery}
                                                        onChange={handleSearchChange}
                                                        type='text'
                                                        endAdornment={
                                                            <InputAdornment position="end">
                                                                <IconButton>
                                                                    <Search />
                                                                </IconButton>
                                                            </InputAdornment>
                                                        }
                                                        label="Search product/barcode"
                                                    />
                                                </FormControl>
                                            </DynamicSearchBox>
                                            {searchQuery && (
                                                <div ref={searchProduct} className="search-result bg-white flex relative">
                                                    {products && products.length > 0 ? (
                                                        <ul className="overflow-auto flex-1 absolute top-0 end-0 start-0 h-96 bg-white shadow-2xl z-10">
                                                            {products.map((product: any, index: number) => (
                                                                <li className={`border-b border-slate-200 
                                                                p-2 flex items-center gap-2 text-black 
                                                                justify-between hover:bg-primary
                                                                hover:text-white cursor-pointer`}
                                                                    key={index}
                                                                    onClick={() => handleProductClick(product)}
                                                                >
                                                                    <span className="flex-1">{product.prod_title}</span>
                                                                    <sub><strong className="text-uppercase">{product.barCode}</strong></sub>
                                                                    <strong>{product.size} {product.color}</strong>
                                                                </li>
                                                            ))}
                                                        </ul>
                                                    ) : (
                                                        <Typography variant="body2" className="p-2">No products found.</Typography>
                                                    )}
                                                </div>
                                            )}
                                        </Grid>
                                        <Grid item xs={12}>
                                            <table className="w-full customTable">
                                                <thead>
                                                    <tr>
                                                        <th className="text-left">Barcode</th>
                                                        <th className="text-left">Product</th>
                                                        <th className="text-center">Qty</th>
                                                        <th className="text-left">Unit Price</th>
                                                        <th className="text-left">Subtotal</th>
                                                        <th className="text-center">&nbsp;</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {selectedProducts.map((product: any, index: number) => (
                                                        <tr key={index}>
                                                            <td>{product.barCode}</td>
                                                            <td className="text-nowrap">
                                                                <Grid container spacing={1} alignItems='center'>
                                                                    <Grid item>
                                                                        {product.prod_title}
                                                                    </Grid>
                                                                    {product.size && (
                                                                        <Grid item xs='auto'>
                                                                            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                Size:&nbsp;{product.size}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                    {product.colour && (
                                                                        <Grid item xs='auto'>
                                                                            <Typography variant="body1" sx={{ fontSize: '12px', fontWeight: '600' }}>
                                                                                Color:&nbsp;{product.colour}
                                                                            </Typography>
                                                                        </Grid>
                                                                    )}
                                                                </Grid>
                                                            </td>
                                                            <td>
                                                                <div className="flex items-center flex-row justify-center overflow-hidden">
                                                                    <button className="p-2 bg-primaryMain text-white hover:bg-primaryMainHover flex items-center justify-center h-6 w-6 rounded-s-md" onClick={() => decrement(product)}>-</button>
                                                                    <input type="text" placeholder="0" value={product.quantity} className="w-7 h-6 text-center" readOnly />
                                                                    <button className="p-2 bg-primaryMain text-white hover:bg-primaryMainHover flex items-center justify-center h-6 w-6 rounded-e-md" onClick={() => increment(product)}>+</button>
                                                                </div>
                                                            </td>
                                                            <td>
                                                                {product.unit_price}
                                                            </td>
                                                            <td>
                                                                {product.sub_total.toFixed(2)}
                                                            </td>
                                                            <td className="text-center">
                                                                <Tooltip title="Remove item">
                                                                    <Button onClick={() => handleProductRemove(product.barCode)} variant="text" sx={{ padding: 0, border: 0, minWidth: '24px', color: colorTheme.palette.topLink, '&:hover': { border: 0, color: colorTheme.palette.secondary.main }, fontWeight: '600' }}>
                                                                        <DeleteOutline />
                                                                    </Button>
                                                                </Tooltip>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </Grid>
                                    </Grid>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>

                    <Grid item xs={6} md={8} sx={{ paddingTop: '0px' }}>
                    </Grid>

                    <Grid item xs={6} md={4} sx={{ paddingTop: '0px' }}>
                        <Grid item xs={12}>
                            <Box sx={cardItem}>
                                <Grid container spacing={1} alignItems='center'>
                                    <Grid item xs={12}>
                                        <Typography variant='subtitle1' sx={{ fontWeight: 600, color: colorTheme.palette.primary.main }}>Summary</Typography>
                                    </Grid>

                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" sx={labelItem}>Invoice no.</Typography>
                                    </Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}>{invoiceDetails?.results[0]?.dt_sequence_no}</Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" sx={labelItem}>Customer name</Typography>
                                    </Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}>{invoiceDetails?.results[0]?.customer_name}</Grid>
                                    <Grid item xs={4}>
                                        <Typography variant="subtitle2" sx={labelItem}>Customer mobile no.</Typography>
                                    </Grid>
                                    <Grid item xs={1}>:</Grid>
                                    <Grid item xs={7}>{invoiceDetails?.results[0]?.customer_contact}</Grid>

                                    {!credit_note ? (
                                        <>
                                            <Grid item xs={4}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>Net Total</Typography>
                                            </Grid>
                                            <Grid item xs={1}></Grid>
                                            <Grid item xs={7}>
                                                <Typography variant="subtitle1" sx={{ fontWeight: '600' }}>₹&nbsp;
                                                    {bill_total || 0} /-
                                                </Typography>
                                            </Grid>
                                        </>
                                    ) : (
                                        <></>
                                    )}

                                    <div className="exchange-btn-wrap">
                                        <div>

                                            {!credit_note ? (
                                                <>
                                                    <Button onClick={exchangeValidateHandler} sx={{ marginTop: "15px" }} variant="contained">
                                                        Genrate credit note
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Button onClick={exchangeSubmitHandler} sx={{ marginTop: "15px" }} variant="contained">
                                                        Submit Exchange
                                                    </Button>
                                                </>
                                            )}

                                        </div>
                                        <div>
                                            <PrintButton
                                                customerName={invoiceDetails?.results[0]?.customer_name || ''}
                                                customerContact={invoiceDetails?.results[0]?.customer_contact || ''}
                                                dtSequenceNo={invoiceNumber || ''}
                                                netTotal={creditNote?.bill_total}
                                                creditNoteProdDetails={creditNote || []}
                                                disabled={!invoiceNumber}
                                            />
                                        </div>
                                    </div>

                                </Grid>
                            </Box>
                        </Grid>
                    </Grid>

                    <ThemeProvider theme={paymentModes}>
                        <Grid className="paymentButtons" container spacing={2} textAlign='center' flexWrap='nowrap'>
                            <Grid item>
                                <Item onClick={() => handleOpen('Cash')} sx={{ ...transactionBtn }} className={credit_note ? 'disabled cash' : 'cash'}>
                                    <div className="bits"><img src={cash} alt="Cash payment" /></div>
                                    Cash
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item onClick={() => handleOpen('Card')} sx={{ ...transactionBtn }} className={credit_note ? 'disabled creditCard' : 'creditCard'}>
                                    <div className="bits"><img src={creditCard} alt="Credit card payment" /></div>
                                    Card
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item onClick={() => handleOpen('UPI')} sx={{ ...transactionBtn }} className={credit_note ? 'disabled upi' : 'upi'}>
                                    <div className="bits"><img src={upi} alt="UPI payment" /></div>
                                    UPI
                                </Item>
                            </Grid>
                            <Grid item>
                                <Item onClick={() => handleOpen('Multi Modes')} sx={{ ...transactionBtn }} className={credit_note ? 'disabled multiModes' : 'multiModes'}>
                                    <div className="bits"><img src={multiModes} alt="Multi modes" /></div>
                                    Multi Modes
                                </Item>
                            </Grid>
                        </Grid>
                    </ThemeProvider>

                    <PaymentModal
                        open={open}
                        handleClose={handleClose}
                        modalTitle={modalTitle}
                        saleSummary={bill_total}
                        paymentAmtBtn={paymentAmtBtn}
                        paymentAmt={paymentAmt}
                        handleSetPayment={handleSetPayment}
                        paymnetHandler={paymnetHandler}
                        transactionCode={transactionCode}
                        transactionCodeHandler={transactionCodeHandler}
                        createInvoiceHandler={exchangeSubmitHandler}
                        halfPaymentHandle={halfPaymentHandle}
                        exchangeProducts={true}
                    />
                </Grid>
            </section>
        </>
    );
}

export default ExchangeProducts;


